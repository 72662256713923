import LEH from './LEH.png'; // Make sure the path to your image is correct
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-content">
        <img src={LEH} className="LEH-logo" alt="London Elite Health" />
        <div className="Health-info">
          <br />
          <br />
          <br />
          <br />
          <p>Exclusive Personalised Healthcare</p>
          <p className='smaller-font'>Covering you globally, 24 hours a day, 365 days a year from the heart of London</p>
          <br />
          <br />
          <br />
          <br />
          <p className='smaller-font'>BY INVITATION ONLY</p>
        </div>
      </div>
    </div>
  );
}

export default App;
